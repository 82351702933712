import InputGroup from "components/form/input/InputGroup";
import useMergeReducer from "./useMergeReducer";
import { always } from "util/func";
import { ensureOptionalProp, ensureProp } from "util/result";
import { gt } from "util/pred";
import { isBoolean } from "crocks/predicates";
import { onInputEvent } from "@s-e/frontend/callbacks/event/input";
import { useCallback, useMemo } from "react";
import { validateNumericInputValue } from "util/helpers";
import {
  bimap,
  constant,
  curry,
  defaultProps,
  either,
  getPathOr,
  getPropOr,
  hasProp,
  identity,
  ifElse,
  isEmpty,
  isSame,
  isString,
  not,
  objOf,
  pipe,
  propEq,
  reduce,
  Result,
  setProp,
} from "crocks";

export const getFormResult = curry((formInput, form) =>
  pipe(
    Result.Ok,
    ...Object.entries(formInput).reduce((carry, [key, opts]) => {
      const ensure = propEq("opt", true, opts)
        ? ensureOptionalProp
        : ensureProp;
      return [
        ...carry,
        ensure(
          key,
          getPropOr(() => false, "validator", opts),
          getPropOr("Privalomas laukelis", "message", opts),
        ),
      ];
    }, []),
    bimap(identity, () => form),
  )(form),
);

export const isComplete = either(
  () => false,
  () => true,
);
export const isFullyComplete = curry((result, form) =>
  either(
    () => false,
    () =>
      Object.values(form).filter((a) => a !== "").length ===
      Object.values(form).length,
    result,
  ),
);

/**
 * @type FormInputProps
 * @param {(value: string) => void} onChange
 * @param {string} value
 */

/**
 * @type FormInput
 * @param {(value: string) => boolean} validator
 * @param {string} initial
 * @param {boolean} opt
 * @param {string} message
 * @param {FormInputProps[]} props
 */

/**
 * @param {Object.<string, FormInput>} obj
 */
export const useResultForm = (obj) => {
  const [state, setState] = useMergeReducer(
    pipe(
      Object.entries,
      reduce(
        (carry, [key, opts]) =>
          setProp(key, getPropOr("", "initial", opts), carry),
        {},
      ),
    )(obj),
  );

  const result = useMemo(() => getFormResult(obj, state), [state]);
  const _isComplete = useMemo(() => isComplete(result), [result]);
  const _isFullyComplete = useMemo(
    () => isFullyComplete(result, state),
    [result, state],
  );
  const set = useMemo(
    () => curry((key, value) => setState(objOf(key, value))),
    [setState],
  );
  const isValid = useCallback(
    (key) => result.either(not(hasProp(key)), () => true),
    [result],
  );
  const getValid = useCallback(
    (key) => result.either(constant(null), getPropOr(null, key)),
    [result],
  );
  const control = useCallback(
    (key) => ({
      ...pipe(
        getPathOr({}, [key, "props"]),
        Object.entries,
        reduce(
          (carry, [prop, value]) =>
            setProp(
              prop,
              value({
                isValid: isValid(key),
                message: result.either(
                  getPropOr(undefined, key),
                  () => undefined,
                ),
                value: getPropOr(undefined, key, state),
                set: set(key),
                setForm: setState,
              }),
              carry,
            ),
          {},
        ),
      )(obj),
    }),
    [state, set, isValid, result, obj],
  );

  return {
    ctrl: control,
    isValid,
    getValid,
    form: state,
    setForm: setState,
    set,
    result,
    isComplete: _isComplete,
    isFullyComplete: _isFullyComplete,
  };
};

export default useResultForm;

const FORM_FIELD = {
  TEXT: ({
    label,
    initial = "",
    props = {},
    showValidationBelow = true,
    ...obj
  }) =>
    defaultProps(
      {
        validator: not(isEmpty),
        initial,
        props: {
          onChange:
            ({ set }) =>
              ({ target: { value } }) =>
                set(value),
          value: ({ value }) => (isString(value) ? value : String(value)),
          ...(label ? { label: always(label) } : {}),
          ...(showValidationBelow
            ? {
              below: ({ isValid, message }) =>
                !isValid && message ? (
                  <InputGroup.ErrorText>{message}</InputGroup.ErrorText>
                ) : null,
            }
            : {}),
          ...props,
        },
      },
      obj,
    ),
  SELECT: ({
    label,
    initial = "",
    showValidationBelow = true,
    props = {},
    ...obj
  }) =>
    defaultProps(
      {
        validator: not(isEmpty),
        initial,
        props: {
          onChange: ({ set }) => set,
          value: ({ value }) => value,
          ...(label ? { label: always(label) } : {}),
          ...(showValidationBelow
            ? {
              below: ({ isValid }) => !isValid,
            }
            : {}),
          ...props,
        },
      },
      obj,
    ),
  BOOL: ({ label, initial = false, props = {}, ...obj }) =>
    defaultProps(
      {
        validator: not(isEmpty),
        initial,
        props: {
          onClick:
            ({ set, value }) =>
              () =>
                set(!value),
          onChange:
            ({ set }) =>
              (e) =>
                set(e.target.checked),
          isChecked: ({ value }) => (isBoolean(value) ? value : initial),
          ...(label ? { label: always(label) } : {}),
          below: ({ isValid, message }) =>
            !isValid && message ? (
              <InputGroup.ErrorText>{message}</InputGroup.ErrorText>
            ) : null,
          ...props,
        },
      },
      obj,
    ),
  EVENTS: ({ label, initial = [], ...obj }) =>
    defaultProps(
      {
        validator: not(isEmpty),
        initial,
        props: {
          setValue: ({ set }) => set,
          value: ({ value }) => value,
        },
      },
      obj,
    ),
  OBJECT: ({ label, initial = {}, ...obj }) =>
    defaultProps(
      {
        validator: not(isEmpty),
        initial,
        props: {
          setValue: ({ set }) => set,
          value: ({ value }) => value,
        },
      },
      obj,
    ),
  ARRAY: ({ label, initial = [], ...obj }) =>
    defaultProps(
      {
        validator: not(isEmpty),
        initial,
        props: {
          setValue: ({ set }) => set,
          value: ({ value }) => [value],
        },
      },
      obj,
    ),
  DECIMAL: ({
    initial = "",
    label = "",
    props = {},
    showValidationBelow = true,
    testId = "",
    validator = ifElse(isSame(""), constant(true), gt(0)),
    ...obj
  }) =>
    defaultProps(
      {
        initial,
        validator,
        props: {
          inputMode: always("decimal"),
          twLabel: always("text-xxs mb-1"),
          placeholder: always("0"),
          onChange: ({ set }) =>
            onInputEvent("", pipe(validateNumericInputValue, set)),
          isInvalid: ({ isValid }) => !isValid,
          value: ({ value }) => value || initial,
          ...(label ? { label: always(label) } : {}),
          ...(testId ? { "data-testid": always(testId) } : {}),
          ...(showValidationBelow
            ? {
              below: ({ isValid, message }) =>
                !isValid && message ? (
                  <InputGroup.ErrorText>{message}</InputGroup.ErrorText>
                ) : null,
            }
            : {}),
          ...props,
        },
      },
      obj,
    ),
};

export { FORM_FIELD };
