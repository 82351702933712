import { FORM_FIELD } from "hook/useResultForm";
import { always } from "util/func";
import { hasLength } from "@s-e/frontend/pred";
import { mNumber } from "util/number";
import {
  and,
  constant,
  curry,
  getPath,
  getProp,
  getPropOr,
  identity,
  isEmpty,
  isFalsy,
  isTruthy,
  map,
  mapProps,
  Maybe,
  not,
  option,
  or,
  pick,
  pipe,
  propSatisfies,
  setProp,
} from "crocks";
import { gt } from "util/pred";

export const cargoFromGqlToForm = (gql) => ({
  key: undefined,
  category: (gql?.cargo_transportation_category ?? [])?.find(
    (value) => value?.name && !value?.parent,
  ),
  subCategory: (gql?.cargo_transportation_category ?? [])?.find(
    (value) => value?.name && value?.parent,
  ),
  description: gql?.description,
  quantity: gql?.quantity,
  length: gql?.length,
  width: gql?.width,
  height: gql?.height,
  weight: gql?.weight,
  isPacked: gql?.is_packed,
  isInsuranceNeeded: gql?.is_insured,
  price: gql?.price,
});

export const getCargoFormFields = ({ t, form, wasSubmitted }) => ({
  key: {
    initial: form?.key || +new Date(),
    message: "",
    validator: always(true),
    opt: true,
  },
  category: FORM_FIELD.SELECT({
    label: t("select_category"),
    validator: propSatisfies("id", not(isEmpty)),
    initial: form?.category || "",
    showValidationBelow: wasSubmitted,
    props: {
      displayValue: (a) => constant(t(a?.value?.name)),
      onChange:
        ({ setForm }) =>
          (category) =>
            setForm({
              category,
              subCategory: "",
            }),
      twLabel: always("text-xxs mb-1"),
      btnClass: always("h-[50px]"),
      "data-testid": always("category"),
      isInvalid: ({ isValid }) => !isValid,
    },
  }),
  subCategory: FORM_FIELD.SELECT({
    label: t("select_sub_category"),
    initial: form?.subCategory || "",
    validator: always(true),
    props: {
      twLabel: always("text-xxs mb-1"),
      btnClass: always("h-[50px]"),
      placeholder: always(t("select_sub_category")),
      displayValue: pipe(
        getPath(["value", "name"]),
        map(t),
        option(""),
        constant,
      ),
      "data-testid": always("sub-category"),
    },
  }),
  description: FORM_FIELD.TEXT({
    initial: form?.description || "",
    label: t("cargo_info"),
    validator: hasLength,
    showValidationBelow: wasSubmitted,
    props: {
      twLabel: always("text-xxs mb-1"),
      isInvalid: ({ isValid }) => !isValid,
      "data-testid": always("cargo-description"),
    },
  }),
  quantity: FORM_FIELD.TEXT({
    initial: form?.quantity || "1",
    validator: always(true),
    label: t("quantity"),
    props: {
      onChange: ({ set }) => pipe((value) => Math.max(1, value), set),
      /**
       * @todo: handle this correctly
       * getPrices()
       */
      "data-testid": always("quantity"),
    },
  }),
  length: FORM_FIELD.DECIMAL({
    initial: form?.length || "",
    label: t("length_mm"),
    message: t("required_length"),
    testId: "length",
    showValidationBelow: wasSubmitted,
  }),
  width: FORM_FIELD.DECIMAL({
    initial: form?.width || "",
    label: t("width_mm"),
    message: t("required_width"),
    testId: "width",
    showValidationBelow: wasSubmitted,
  }),
  height: FORM_FIELD.DECIMAL({
    initial: form?.height || "",
    label: t("height_mm"),
    message: t("required_height"),
    testId: "height",
    showValidationBelow: wasSubmitted,
  }),
  weight: FORM_FIELD.DECIMAL({
    initial: form?.weight || "",
    label: t("weight_kg"),
    message: t("required_weight"),
    testId: "weight",
    showValidationBelow: wasSubmitted,
  }),
  isPacked: FORM_FIELD.BOOL({
    initial: form?.isPacked || false,
    validator: () => true,
    props: {
      value: getPropOr("", "value"),
      options: always([
        { text: t("yes"), value: true },
        { text: t("no"), value: false },
      ]),
      onChange: ({ set }) => set,
      "data-testid": always("is-packed"),
    },
  }),
  isInsuranceNeeded: FORM_FIELD.BOOL({
    initial: form?.isInsuranceNeeded || false,
    validator: always(true),
    props: {
      value: ({ value }) => value,
      options: always([
        { text: t("yes"), value: true },
        { text: t("no"), value: false },
      ]),
      onChange: ({ set }) => set,
      "data-testid": always("is-insurance-needed"),
    },
  }),
  price: FORM_FIELD.DECIMAL({
    opt: true,
    initial: form?.price || "",
    validator: pipe(
      parseFloat,
      or(
        and(() => isTruthy(form.isInsuranceNeeded), and(gt(0), not(isNaN))),
        () => isFalsy(form?.isInsuranceNeeded),
      ),
    ),
    label: t("cargo_value"),
    message: t("required_amount"),
    testId: "price",
    showValidationBelow: wasSubmitted,
  }),
});

export const getCargoFormFieldsEmpty = always(
  pipe(
    getCargoFormFields,
    Object.entries,
    map(([key, obj]) => [key, obj?.initial || ""]),
    Object.fromEntries,
  )({ t: identity, form: null }),
);

export const cargoFormToGql = curry((insuranceRate, form) =>
  pipe(
    pick([
      "description",
      "height",
      "length",
      "price",
      "quantity",
      "weight",
      "width",
    ]),
    setProp("category_id", form?.category?.id),
    setProp(
      "insured_price",
      getProp("price", form)
        .chain(mNumber)
        .map((num) => num * insuranceRate * 0.01 + num)
        .alt(Maybe.of(0))
        .option(0),
    ),
    setProp("is_insured", isTruthy(form?.isInsuranceNeeded)),
    setProp("is_packed", isTruthy(form?.isPacked)),
    mapProps({
      height: parseFloat,
      insured_price: parseFloat,
      length: parseFloat,
      price: parseFloat,
      quartity: parseInt,
      weight: parseFloat,
      width: parseFloat,
    }),
  )(form),
);
